<!--
 * @Autor: oops.liu
-->
<template>
  <div class="not-found">
    <navbarbox>
      <van-nav-bar
        title="页面未找到"
        left-arrow
        :border="false"
        @click-left="handleLeft"
      />
    </navbarbox>
    <div class="content-box">
      <scroller>
        <div class="content">
          <van-empty image="error" description="页面出错啦！！！" />
        </div>
      </scroller>
    </div>
  </div>
</template>

<script>
import navbarbox from "@/components/common/navbarbox.vue";
import handleLeft from "@/mixin/handleLeft.js";
export default {
  components: {
    navbarbox,
  },
  mixins: [handleLeft],
 
};
</script>

<style scoped lang="scss">
.not-found {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  ::v-deep .van-nav-bar__left {
    .van-icon {
      color: #333;
    }
  }
  .content-box {
    position: relative;
    width: 100%;
    height: calc(100% - 70px);
    .content {
      padding-top: 50px;
    }
  }
}
</style>
